.bg-verify-overlay {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-position: center center;
  background: rgba(0, 0, 0, 0.85) !important;
  z-index: 2147483647;
}
.age-verify-popup {
  max-width: 90%;
  background: #f1e9e7;
  width: 450px;
  padding: 40px 20px;
  border-radius: 4px;
  text-align: center;
  font-family: sans-serif;
  line-height: 1.2;
  -webkit-box-shadow: 0px 0px 25px -5px rgb(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 25px -5px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 25px -5px rgb(0, 0, 0, 0.75);
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  width: 500px !important;
  max-width: 95% !important;
  max-height: 90% !important;
  overflow-y: auto;
  transform: translateX(-50%) translateY(-50%);
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: initial;
  z-index: 2147483647;
}
@media all and (max-width: 800px) {
  .age-verify-popup {
    padding: 30px 10px;
    width: 330px !important;
  }
}
.age-verify-logo {
  margin-top: -10px;
  margin-bottom: 0 !important;
  text-align: center;
}
.m-a-v__header {
  margin-bottom: 20px;
}
.m-a-v__headline {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
  word-wrap: break-word;
}
.m-a-v__action + .m-a-v__action {
  margin-top: 10px;
  margin-bottom: 10px !important;
  font-size: 14px !important;
}
.age-verify-sub-heading {
  font-size: 14px;
  word-wrap: break-word;
}

.age-verify-button-enter {
  padding: 6px 16px;
  cursor: pointer;
  border: 0;
  border-radius: 4px;
  box-shadow: none;
  background-image: none;
  transition: unset;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: initial;
  height: auto;
  min-height: auto;
  width: auto;
  max-width: auto;
  margin: 0;
  padding: 10px 30px !important;
  font-weight: 300 !important;
  background: #ffe204;
  color: black !important;
  font-size: 14px !important;
}
.age-verify-logo-image {
  max-width: 350px;
  margin: auto;
}
